export const useImageUrl = () => {
  const config = useRuntimeConfig()

  const getImageUrl = (path: string) => {
    const cleanPath = path.startsWith('/') ? path.slice(1) : path

    if (process.env.NODE_ENV === 'development') {
      return `http://${cleanPath}`
    }

    return `${cleanPath}`
  }

  return {
    getImageUrl
  }
}